import React, { Component } from 'react';
// import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PageWrapper from '../styles/stats.css';

import TextNavigationList from 'components/Global/TextNavigationList';
import PlayersOfTheWeekCard from 'components/Stats/PlayersOfTheWeekCard';
import TrendingCard from 'components/Stats/TrendingCard';
import TeamLeaderboardCard from 'components/Stats/TeamLeaderboardCard';
import StatsTable from 'components/Global/StatsTable';
import ItemBuilds from 'components/Stats/ItemBuild';


class Stats extends Component {

  constructor(props) {
    super(props);

    this.state = {
      overviewData: null,
      playerStatsData: null,
      teamStatsData: null,
      recentBuildsData:null,
      toolTipData: {},
      Xposition: null,
      Yposition: null,
      isLoaded: false,
      view: 'Overview',
      sortCriteria: 'rank'
    }
  }

  getOverviewData = (id1) => {
    fetch(`${this.props.context.baseUrl}/eventoverview/${id1}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        overviewData: response
      });
    });
  }

  getPlayerStatsData = (id1) => {
    fetch(`${this.props.context.baseUrl}/stats/${id1}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        playerStatsData: response
      });
    });
  }

  getTeamStatsData = (id1) => {
    fetch(`${this.props.context.baseUrl}/teamstats/${id1}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        teamStatsData: response
      });
    });
  }

  getRecentBuildsData = (id1) => {
    fetch(`${this.props.context.baseUrl}/recentbuilds/${process.env.GATSBY_LEAGUE_ID}/${id1}`)
    .then(response => response.json())
    .then(response => {
      console.log(response)
      this.setState({
        recentBuildsData: response
      });
    });
  }
  getEvents = () => {
    fetch(`${this.props.context.baseUrl}/eventlist/${process.env.GATSBY_LEAGUE_ID}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        events: response
      });
    }); 
  }
  getGods = () => {
    fetch(`${this.props.context.baseUrl}/godlist`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        gods: response
      });
    }); 
  }

  setSortCriteria = (stat) => {
    this.setState({
      sortCriteria: stat,
      reverseOrder: this.state.reverseOrder ? false : true
    });
  }

  sortByStat = (a,b) => {
    if (typeof a[this.state.sortCriteria] === 'number') {
      if (this.state.reverseOrder)  {
        return b[this.state.sortCriteria] - a[this.state.sortCriteria];
      }
      else {
        return a[this.state.sortCriteria] - b[this.state.sortCriteria];
      }
    }
    if (typeof a[this.state.sortCriteria] === 'string')  {
      if (this.state.reverseOrder)  {
        if (b[this.state.sortCriteria].toLowerCase() < a[this.state.sortCriteria].toLowerCase()) {
          return -1;
        }
      }
      else {
        if (b[this.state.sortCriteria].toLowerCase() > a[this.state.sortCriteria].toLowerCase()) {
          return -1;
        }
      }
    }
  }

  getItemDetails = () => {
    fetch("https://cms.smitegame.com/wp-json/smite-api/getItems/1")
      .then(response => response.json())
      .then(response => {
        console.log(response)
      this.setState({
        itemDetails: response
      });
    });
  }

  loadToolTip = (name, Xposition, Yposition, isLoaded) => {
    this.setState({
      isLoaded: isLoaded
    })
    this.state.itemDetails.find(item => {
      if (item.DeviceName === name) {
        this.setState({
          toolTipData: item,
          Xposition: Xposition,
          Yposition: Yposition,
        })
      }
    })
  }
  
  componentDidMount() {
    this.getOverviewData(this.props.context.eventId);
    this.getPlayerStatsData(this.state?.events?.[0]?.event_id||this.props.context.eventId);
    this.getTeamStatsData(this.props.context.eventId);
    this.getRecentBuildsData();
    this.getEvents(this.props.context.eventId);
    this.getGods();
    this.getItemDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.view !== this.state.view) {
      this.getPlayerStatsData(this.state?.events?.[0]?.event_id||this.props.context.eventId);
      this.getTeamStatsData(this.props.context.eventId);
    }
  }

  render() {
    return (
      <PageWrapper>
        <div className="stats-container">
          <h1>{process.env.GATSBY_LEAGUE_HEADER_LONG} Statistics</h1>
          <TextNavigationList 
            data={['Overview','Players','Teams','Builds']}
            activeCallback={(e)=>this.setState({view: e})}
          />
          {this.state.view === 'Overview' && this.state.overviewData !== null &&
            <div className="overview-wrapper">
              {process.env.GATSBY_LEAGUE==='SPL' ? <PlayersOfTheWeekCard data={this.state.overviewData.potw} /> : ''}
              <TrendingCard data={this.state.overviewData.trending} />
              <TeamLeaderboardCard data={this.state.overviewData.team_leaders} />
            </div>
          }
          {this.state.view === 'Players' && this.state.playerStatsData !== null &&
            <div className="players-wrapper">
              <div className="filter-wrapper">
                <select onChange={(e) => this.getPlayerStatsData(e.target.value)}>
                  {this.state.events && this.state.events.map((event, i) => 
                    <option value={event.event_id} key={event.event_id + event.event_name + i}>{event.event_name}</option>
                  )}
                </select>
              </div>
              <StatsTable 
                headings={{
                  // rank: 'Rk',
                  playerName: 'Player',
                  role: 'Role',
                  team_name: 'Team',
                  kills: 'Kills',
                  deaths: 'Deaths',
                  assists: 'Assists',
                  damage_mitigated: 'MTGTD DMG',
                  player_damage: 'PLYR DMG',
                  damage_taken: 'DMG TKN',
                  gold_earned: 'Gold',
                  gpm: 'GPM',
                  wards_placed: 'Wards',
                  games_played: 'Games',
                  structure_damage: 'Str DMG'
                }}
                data={this.state.playerStatsData.sort(this.sortByStat)}
                showIndex={false}
                sortFunction={this.setSortCriteria}
                sortCriteria={this.state.sortCriteria}
              />
            </div>
          }
          {this.state.view === 'Teams' && this.state.teamStatsData !== null &&
            <div className="teams-wrapper">
              <div className="filter-wrapper">
                <select onChange={(e) =>  this.getTeamStatsData(e.target.value)}>
                  {this.state.events && this.state.events.map((event,i) => 
                    <option value={event.event_id} key={event.event_id + i + event.event_name}>{event.event_name}</option>
                  )}
                </select>
              </div>
              <StatsTable 
                headings={{
                  // rank: 'Rk',
                  // team_id: 'team_id',
                  team_name: 'Team',
                  kills: 'Kills',
                  death: 'Deaths',
                  assists: 'Assists',
                  damage_mitigated: 'MTGTD DMG',
                  player_damage: 'PLYR DMG',
                  damage_taken: 'DMG TKN',
                  gold_earned: 'Gold',
                  gpm: 'GPM',
                  wards_placed: 'Wards',
                  structure_damage: 'Str DMG',
                  gold_furies: 'Gold Furys',
                  fire_giants: 'Fire Giants',
                  games_played: 'Games'
                }}
                data={this.state.teamStatsData.sort(this.sortByStat)}
                showIndex={false}
                sortFunction={this.setSortCriteria}
                sortCriteria={this.state.sortCriteria}
              />
            </div>
          }
          {this.state.view === 'Builds' && this.state.recentBuildsData !== null &&
            <div className="teams-wrapper">
              <div className="filter-wrapper">
                <select onChange={(e) =>  this.getRecentBuildsData(e.target.value)}>
                <option value="All" key="All">All</option>
                  {this.state.gods && this.state.gods.map((god,i) => 
                    <option value={god.godName} key={god.godName}>{god.godName}</option>
                  )}
                </select>
              </div>
              {console.log('Redrawing builds')}
              <ItemBuilds
                headings={{
                  date: 'Date',
                  god: 'God',
                  playerName: 'Player',
                  team_name: 'Team',
                  winloss: 'W/L',
                  slash_line: 'K/D/A',
                  build: 'Build',
                  relics: 'Relics'
                }}
                data={this.state.recentBuildsData}
                showIndex={false}
                loadToolTip={this.loadToolTip}
                toolTipData={this.state.toolTipData}
                Xposition={this.state.Xposition}
                Yposition={this.state.Yposition}
                isLoaded={this.state.isLoaded}
              />
            </div>
          }
        </div>
      </PageWrapper>
    );
  }
}

export default withContext(Stats);
