import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './ItemBuild.css';
import { Link } from 'gatsby';

//Components
import ToolTip from '../../Global/Tooltip/Tooltip';

const ItemBuilds = (props) => {
  const {
    data,
    toolTipData,
    loadToolTip,
    Xposition,
    Yposition,
    isLoaded
  } = props;
	return (
    <ComponentWrapper className="c-GameStats">
      <h4>Game Stats</h4>
      <div className="grid-wrapper">
        <div className="grid-container">
          <div className="matchup">Date</div>
          <div className="god">God</div>
          <div className="god">Player</div>
          <div className="god">Team</div>
          <div className="god">W/L</div>
          <div className="final-build icon">K / D / A</div>
          <div className="relics">Relics</div>
          <div className="final-build">Final Build</div>

          {data.map((item, index) =>
            <React.Fragment key={item.date + index}>
              <div className="item">
                {item.date && new Date(item.date*1000).toLocaleDateString(navigator.language, {month: 'short', day: 'numeric', year: 'numeric'})}
              </div>
              
              <div className="item">
                <img src={`https://webcdn.hirezstudios.com/smite/god-icons/${item.god.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, "-")}.jpg`} alt={item.god} />
                {item.god}
              </div>
              <div className="item">{item.player}</div>
              <div className="item">{item.team}</div>
              <div className="item">{item.winloss === '0' ? "Loss" : "Win"}</div>
              
              
              <div className="item icon">{item.kills} / {item.deaths} / {item.assists}</div>
              <div className="item">
                <ul className='relic-items'>
                {item.relics.map((relic, index) =>(
                  relic && <li key={relic + index}>
                      {relic !== 'null' && <img 
                        className='relic-item'
                        onMouseOver={(e) => loadToolTip(relic, e.clientX, e.clientY, true)}
                        onMouseOut={() => loadToolTip(null, 0, 0, false)}
                        src={`https://webcdn.hirezstudios.com/smite/item-icons/${relic.toLowerCase().replace(/[^a-zA-Z- ]/g, "").replace(/\s+/g, '-')}.jpg`} 
                        alt={relic} 
                      />}
                  </li>
                ))}
                </ul>
              </div>
              <div className="item">
                <ul className='build-items'>
                {item.build.map((buildItem, index) =>(
                    <li key={buildItem + index}>
                      {buildItem !=='null' && <img 
                          className='build-item'
                          onMouseOver={(e) => loadToolTip(buildItem, e.clientX, e.clientY, true)}
                          onMouseOut={() => loadToolTip(null, 0, 0, false)}
                          src={`https://webcdn.hirezstudios.com/smite/item-icons/${buildItem.toLowerCase().replace(/[^a-zA-Z- ]/g, "").replace(/\s+/g, '-')}.jpg`} 
                          alt={buildItem} 
                        />}
                    </li>
                  ))}
                    
                </ul>
              </div>
              </React.Fragment>
          )}
        </div>
        {isLoaded &&
          <ToolTip
            toolTipData={toolTipData}
            Xposition={Xposition}
            Yposition={Yposition}
          />
        }
      </div>
    </ComponentWrapper>
	); 
};

ItemBuilds.propTypes = {
  data: PropTypes.array.isRequired,
  toolTipData: PropTypes.object,
  loadToolTip: PropTypes.func,
  Xposition: PropTypes.number,
  Yposition: PropTypes.number,
  isLoaded: PropTypes.bool
}

export default ItemBuilds;